import * as auth0 from '@auth0/auth0-react';
import axios from "axios";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Delegate, EventDelegate } from "../delegate";
import useHttp from "./use-http";
import { useMapping } from "./use-mapping";
import { useNavigate } from 'react-router-dom';
import { useTracking } from '../data/tracking-service';
import { ErrorLabel } from '../ui/labels/error-label';

export enum Scope {
  Admin = 'admin',
  FundraisersCreate = 'fundraisers:create',
  ProfilesCreate = 'profiles:create',
}

export type Auth = {
  subject: string;
  scopes: Scope[];
  accountId: string;
}

const AuthCtx = createContext<{
  value: Auth | null;
  isLoading: boolean;
  login: () => Promise<void>;
  logout: () => void;
  onLogout: Delegate<void>;
}>(null as any);

export const AuthProvider = ({
  children,
}: {
  children: ReactNode;  
}) => {
  const http = useHttp();
  const tracking = useTracking();
  const [ value, setValue ] = useState<Auth | null>(null);
  const [ loginError, setLoginError ] = useState<Error | null>(null);

  const [ checkingAuth, setCheckingAuth ] = useState(true);
  const [ accountId, setAccountId ] = useState<string | null>(null);
  const {
    isLoading: auth0Loading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout: auth0Logout,
    getAccessTokenSilently,
  } = auth0.useAuth0();
  const nav = useNavigate();

  const onLogout = useMapping(() => new EventDelegate<void>, []);
  const logout = async () => {
    if (value === null) return;

    tracking.create({
      subject: user?.sub,
      action: 'logout',
    });
    await http.client.post('/api/auth/logout');
    sessionStorage.removeItem('auth');
    setValue(null);
    sessionStorage.setItem('returnTo', window.location.href);

    if (!value.subject.startsWith('qwik|')) {
      await auth0Logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  }

  useEffect(() => {
    http.client.interceptors.response.use(null, err => {
      if (!err.response) return Promise.reject(err);

      const resp = err.response;
      if (resp.status === 401) {
        const body = resp.data;
        if ('issue' in body) {
          switch (body.issue) {
            case 'invalid-username':
            case 'invalid-password':
              break;

            default:
              login();
          }
        }
      }

      return Promise.reject(err);
    });
  }, [ http.client ]);

  const login = async () => {
    tracking.create({
      subject: user?.sub,
      action: 'login',
    });
    sessionStorage.setItem('returnTo', window.location.href)
    await loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
    });
  }

  useEffect(() => {
    if (auth0Loading) {
      return;
    }

    // if (user && !user.email_verified) {
    //   setLoginError(new Error('Please check your email and verify.'));
    //   return;
    // }

    tracking.create({
      subject: value?.subject || user?.sub,
      action: 'auth-get-token',
    });
    http.client.get('/api/auth')
    .then(r => r.data)
    .then(async auth => {
      if (auth) {
        setValue(auth);
        setAccountId(auth.accountId);
        setCheckingAuth(false);
        return;
      }

      return await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://qwikraisefundraising.com',
        },
      }).then(async token => {
        tracking.create({
          subject: value?.subject || user?.sub,
          action: 'auth-get-token-success',
        });
        try {
          const auth = await axios.post('/api/auth', { 
            token,
          }).then(r => r.data);
          setValue(auth);
          if (auth !== null) {
            setAccountId(auth.accountId);
          }

          tracking.create({
            subject: value?.subject || user?.sub,
            action: 'auth-ok',
            error,
          });
        }
        catch (error: any) {
          if ('issue' in error) {
            if (error.issue === 'account-not-verified') {
              setLoginError(new Error('Please check your email and verify to continue.'));
              return;
            }
          }
          console.log(error);
          tracking.create({
            subject: value?.subject || user?.sub,
            action: 'auth-get-token-error',
            error,
          });
          alert(error);
        }
        setCheckingAuth(false);
      }, error => {
        tracking.create({
          subject: value?.subject || user?.sub,
          action: 'auth-get-token-error',
          error,
        });
        console.log(error);
        setCheckingAuth(false);
      });
    })
  }, [ auth0Loading, user ]);

  const isLoading = useMapping(() => {
    return checkingAuth || auth0Loading;
  }, [ auth0Loading, checkingAuth ]);

  const view = useMapping(() => {
    tracking.create({
      subject: value?.subject,
      action: 'auth-event',
      error,
      isLoading,
      auth0Loading,
      checkingAuth,
    });
    if (error) alert(error);
    if (loginError) return <ErrorLabel error={loginError} />
    if (auth0Loading) return <></>
    if (checkingAuth) return <></>

    return <>{children}</>
  }, [ error, isLoading, checkingAuth, JSON.stringify(value), loginError ]);

  return <AuthCtx.Provider value={{ isLoading, value, login, onLogout, logout }}>
    {view}
  </AuthCtx.Provider>
}

export const useAuth = () => useContext(AuthCtx);

export const useAuthValue = (): Auth => {
  const auth = useAuth();

  return useMapping(() => {
    if (!auth.value) throw new Error('invalid-auth');

    return auth.value;
  }, [ auth.value ]);
}