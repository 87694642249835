import { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAsync } from './hooks/use-async';
import { Auth, AuthProvider, useAuth } from './hooks/use-auth';
import { Auth0Provider, AuthConfig } from './hooks/use-auth0';
import useHttp, { HttpProvider } from './hooks/use-http';
import { useMapping } from './hooks/use-mapping';
import { ConfirmParticipantForm } from './ui/forms/confirm-participant-form';
import { Modal } from './ui/modals/modal';
import { AdminAccountPage } from './ui/pages/admin-account-page';
import { AdminAccountsPage } from './ui/pages/admin-accounts-page';
import { AdminPage } from './ui/pages/admin-page';
import { FundraiserPage } from './ui/pages/fundraiser-page';
import { FundraisersPage } from './ui/pages/fundraisers-page';
import { FundraiserSettingsPage } from './ui/pages/funraiser-settings-page';
import { HomePage } from './ui/pages/home-page';
import { LandingPage } from './ui/pages/landing-page';
import { ParticipantPage } from './ui/pages/participant-page';
import { SearchPage } from './ui/pages/search-page';
import { SettingsPage } from './ui/pages/settings-page';
import { Navbar } from './ui/sections/navbar';
import styles from './App.module.scss';
import { PrivacyPolicyPage } from './ui/pages/privacy-policy-page';
import { SimpleButton } from './ui/buttons/simple-button';
import { AdminFilesPage } from './ui/pages/admin-files-page';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ location ]);

  return <></>
}

const Home = () => {
  const auth = useAuth();

  return useMapping(() => {
    if (!auth.value) return <LandingPage />

    return <HomePage />
  }, [ JSON.stringify(auth.value) ]);
}

const TopModal = () => {
  const http = useHttp();
  const [ view, setView ] = useState<JSX.Element | null>(null);
  const inviteTask = useAsync(async () => await http.client.get('/api/v1/participant-invite-tokens').then(r => r.data));

  const handleClose = () => {
    setView(null);
  }

  useEffect(() => {
    if (!inviteTask.value) return;

    console.log({
      inviteTask,
    });

    setView(
      <ConfirmParticipantForm fundraiserId={inviteTask.value.fundraiserId} inviteId={inviteTask.value.inviteId} onClose={handleClose} />
    );
  }, [ inviteTask.value, inviteTask.loading ]);

  return <Modal enabled={view !== null} onClose={handleClose} setEnabled={handleClose} >
    {view || <></>}
  </Modal>
}

const Authorized = ({
  auth,
}: {
  auth: Auth;  
}) => {
  return <>
    <Routes>
      <Route path="/admin/files" element={<AdminFilesPage />} />
      <Route path="/admin/accounts/:accountId" element={<AdminAccountPage />} />
      <Route path="/admin/accounts" element={<AdminAccountsPage />} />
      <Route path="/participants/:participantId" element={<ParticipantPage />} />
      <Route path="/fundraisers/:fundraiserId/settings" element={<FundraiserSettingsPage />} />
      <Route path="/fundraisers/:fundraiserId" element={<FundraiserPage />} />
      <Route path="/admin" element={<AdminPage auth={auth} />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/fundraisers" element={<FundraisersPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/settings/:setting" element={<SettingsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </>
}

const Unauthorized = () => {
  return <>
    <Routes>
      <Route path="/fundraisers/:fundraiserId" element={<FundraiserPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </>
}

const Routing = () => {
  const auth = useAuth();
  const location = useLocation();
  const nav = useNavigate();

  return useMapping(() => {
    const returnTo = sessionStorage.getItem('returnTo');

    if (returnTo) {
      const url = new URL(returnTo);
      if (location.pathname !== url.pathname) {
        console.log({
          returnTo,
        });
        nav(returnTo.replace(window.origin, ''));
        return <></>
      }
      else {
        sessionStorage.removeItem('returnTo');
      }
    }

    if (auth.isLoading) return <>Loading</>

    if (!auth.value) return <Unauthorized />
    return <Authorized auth={auth.value} />
  }, [ location.pathname, auth.isLoading, JSON.stringify(auth.value) ]);
}

const App = ({
  authConfig,
}: {
  authConfig: AuthConfig;
}) => {
  return <>
    <Auth0Provider config={authConfig}>
    <BrowserRouter>
    <HttpProvider>
    <AuthProvider>
      <div className={styles.app}>
        <ScrollToTop />
        <Navbar />
        <TopModal />
        <div className={styles.main}>
          <Routing />
        </div>
        <footer className={styles.footer}>
          <h6>@QwikRaise 2024. All rights reserved. Website design by JSL Marketing & Web Design.</h6>
          <div className={styles.links}>
            <Link to='/'>
              Home
            </Link>
            <Link to='/privacy-policy'>
              Privacy Policy
            </Link>
          </div>
        </footer>
      </div>
    </AuthProvider>
    </HttpProvider>
    </BrowserRouter>
    </Auth0Provider>
  </>
}

export default App;
