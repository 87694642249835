import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { loadAuthConfig } from './hooks/use-auth0';

(async () => {
  // const returnTo = sessionStorage.getItem('returnTo');
  // if (returnTo) {
  //   if (window.location.href !== returnTo) {
  //     // window.location.href = returnTo;
  //     // window.location.reload();
  //     // sessionStorage.removeItem('returnTo');
  //   }
  //   return null;
  // }

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  await loadAuthConfig()
  .then(authConfig => {
    root.render(
      <App authConfig={authConfig} />
    );
  })
})()
  .catch(err => {
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    root.render(
      <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <span>Sorry! An error has occurred. Please Refresh the page.</span>
        <span>Error {err.message}</span>
      </div>
    )
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
